import { IResource } from '../interfaces/IResouce';
import { extend } from 'webdriver-js-extender';
import { Resource } from './resource.model';
import { Role } from './role.model';
import { Attachment } from 'src/app/core/models/attachment.model';
import { Doctor } from 'src/app/core/models/doctor.model';
import { Clinic } from './clinic.model';

export class User extends Resource implements IResource {
    name: string;
    username: string;
    email: string;
    email_verified_at: Date;
    image_profile: Attachment
    role: number
    doctor?: Doctor
    clinics_view: Clinic[]

    constructor(objectJson?: any) {
        super(objectJson);
    }

}
