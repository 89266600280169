import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { Exam } from '../models/exam.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Serializer } from '../models/serializer.model';
import { of, Observable } from 'rxjs';
import { ExamType } from 'src/app/core/enums/exam.enum';
import { ExamTemplate } from 'src/app/core/models/exam-template.model';
@Injectable({
  providedIn: 'root'
})
export class ExamTemplateService extends ResourceService<ExamTemplate> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.udi_laudos_api,
      'v1',
      'exam-templates',
      new Serializer<ExamTemplate>(new ExamTemplate)
    )
  }

  delete(id: number, confirmationDeleteMessage?) {
    let confirmed = confirm(this.confirmationDeleteMessage);
    if (confirmed) {
      return this.httpClient
        .delete(`${this.url}/${this.version}/${this.endpoint}/${id}`);
    }
  }

  setFavorite(exam_templates: number[]): Observable<any> {
    return this.httpClient.post(`${this.url}/${this.version}/${this.endpoint}/favorite`, {exam_templates})
  }



}
