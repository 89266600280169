import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Serializer } from '../models/serializer.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService extends ResourceService<User> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.udi_laudos_api,
      'v1',
      'users',
      new Serializer<User>(new User)
    )
  }



  setClinicsView(userId: any, data: { clinics: number[] }): Observable<any> {
    return this.httpClient.post(`${this.url}/${this.version}/${this.endpoint}/${userId}/clinics`, data)
  }


}
