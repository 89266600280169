import { Component, OnInit } from '@angular/core';
import { IResource } from 'src/app/core/interfaces/IResouce';
import { ResourceService } from 'src/app/core/services/resource.service';
import { finalize } from 'rxjs/operators';

export abstract class BaseListComponent<T extends IResource> implements OnInit {

  public entitySvc: ResourceService<T>;
  public entityList: T[];
  public confirmationDeleteMessage: string;
  public successDeleteMessage: string;
  public errorDeleteMessage: string;
  public filterParam: any;
  public response: any;
  public inLoading: boolean;
  public getParamObject(param) {
    return {}
  }
  constructor() { }

  ngOnInit() {

  }

  getData(filterObject?) {
    this.inLoading = true;
    this.entitySvc.list(filterObject)
      .pipe(
        finalize(this.completeRequest)
      )
      .subscribe(
        (response) => {
          this.response = response;
          this.entityList = response.data;
        }
      )
  }

  completeRequest = (function (response) {
    this.inLoading = false;
  }).bind(this)

  remove(id: number) {
    this.inLoading = true;
    this.entityList = [];
    this.entitySvc.delete(id)
      .pipe(
        finalize(this.completeRequest)
      )
      .subscribe(
        (response) => {
          alert(this.successDeleteMessage)
          this.getData(this.getParamObject(this.filterParam))
        },
        (error) => {
          alert(this.errorDeleteMessage);
        }
      )
  }



}
